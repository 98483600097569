import React, { Component } from 'react';
import TextEditDialog from './TextEditDialog';
import Loading from '../LoadingComponent';
import {formatHTML} from '../../data/utils';
import * as FiIcons from "react-icons/fi";
import './editors.css';

type TextModalEditProperties = {
    dialogTitle: string,
    recordId: number,
    initialValue: string,
    emptyString?: string;
    singleLine?: boolean;
    rawValue?: boolean;
    editIcon?: boolean;
    recordUpdate(id: number, value: string): Promise<boolean>,
}

export default class TextModalEdit extends Component<TextModalEditProperties, any>{
    constructor(props: any){
        super(props);
        this.state = {
            value: this.props.initialValue,
            dialogOpen: false,
            updating: false,
        }
        this.openEditor=this.openEditor.bind(this);
        this.onOK=this.onOK.bind(this);
        this.onCancel=this.onCancel.bind(this);
    }

    openEditor(){
        this.setState({dialogOpen: true});
    }

    async onOK(value: string){
        this.setState({dialogOpen: false, updating: true});
        if(await this.props.recordUpdate(this.props.recordId, value)){
            this.setState({value: value});
        }
        this.setState({updating: false});
    }

    onCancel(){
        this.setState({dialogOpen: false});
    }

    render(){
        const value = this.state.value ? this.state.value : (typeof(this.props.emptyString) === 'string' ? this.props.emptyString : 'Edit');
        let className = 'text_editable';
        if(!this.state.value)
            className += ' editor_empty';
        if(this.state.updating){
            return (<Loading loading={this.state.updating} fullWidth={false}/>);
        }
        else
            return (
            <>
            <TextEditDialog open={this.state.dialogOpen}
                title={this.props.dialogTitle}
                initialValue={this.state.value}
                onClose={this.onCancel}
                onOK={this.onOK}
                singleLine={this.props.singleLine}
            />
            {
                this.props.editIcon ?
                (
                    (<div className="text_editable_with_icon"><span>{value}</span><FiIcons.FiEdit className="edit-icon" onClick={this.openEditor}/></div>)
                ) :
                (this.props.rawValue ?
                    (
                        <div className={className} onClick={this.openEditor}>{value}</div>
                    ):
                    (
                        <div className={className} onClick={this.openEditor} dangerouslySetInnerHTML={{ __html: formatHTML(value) }} />
                    )
                )
            }
            </>
        );
    }
}