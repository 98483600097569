import { useEffect, useState } from 'react';

import OdooDatabase from '../../data/odoo';
import TableList from '../TableListViewComponent';
import Loading from '../../components/LoadingComponent';

type RelatedWOsProperties = {
    asset_id: number;
}

type Descriptions = {
    key: string;
    label: string;
}

export default function RelatedWOs(props: RelatedWOsProperties){
    const [stateDescriptions, setStateDescriptions] = useState<Descriptions[]>([])

    async function get_states_descriptions() {
        
          try {
            let list:Descriptions[] = []
            let odoo = OdooDatabase.getInstance();
            let result = await odoo.execute_kw('fieldservice.workorder','get_wo_state_names',[[]]);
            result.forEach( (element: any) => {
                list.push({key:element[0],label:element[1]})
            });

            setStateDescriptions(list);

          } catch (err: any) {
            console.log('Error', err);
          }

    }

    useEffect(() => {
        get_states_descriptions();
     }, [])

    return (

        <>
            {
                stateDescriptions.length === 0 ? (
                    <Loading loading={true} fullWidth={false}/>
                ) : (
                    <TableList
                        model="fieldservice.workorder"
                        fields={['name', 'type_as_char', 'date_completed', 'description', 'due_date','state']}
                        domain={[['asset_id','=', props.asset_id], 
                                    '|',
                                    '&', ['quote_wo_type', '=', 'wo'],'!',['state','in',['draft','cancel']],
                                    ['quote_wo_type', '=', 'quote']
                                ]
                            }
                        elements={
                            [
                            {field: 'name', header: 'WO', link: '/wo/__id__'},
                            {field: 'type_as_char', header: 'Type'},
                            {field: 'due_date', header: 'Target Date', type: 'onlydate'},
                            {field: 'date_completed', header: 'Done', type: 'onlydate'},
                            {field: 'description', header: 'Description'},
                            {field: 'state', type: 'select', 'header': 'Status', 'select_items': stateDescriptions}
                            ]
                        }
                        pagination={true}
                        pageSize={5}
                        order="quote_wo_type, date_completed desc, id desc"
                    />
                )
            }
        </>
        
    )
}